import Error from "next/error";
import dynamic from "next/dynamic";
import React from "react";

const RemotePage = dynamic(() => import("./remote-page/RemotePage"));

export default function DynamicPage(props: any = {}) {
  if (Object.keys(props).length === 0) {
    return <div></div>;
  } else {
    const { pageProps = {}, errorCode } = props;
    if (errorCode) {
      return <Error statusCode={errorCode} />;
    } else {
      return <RemotePage {...pageProps} />;
    }
  }
}
