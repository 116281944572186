import React from 'react';
import DynamicPage from "../components/DynamicPage";
import { Helpers, Providers } from "@tpd-web-common-libs/nodejs-library";
import NetworkService from "../providers/network.service";
import { localProdPagePaths } from "../config/localProdPagePaths";
import RoutingService from "../providers/routing.service";
import REMOTES_MAP from "../remotes/remotesMap";
export async function getStaticPaths() {
  let paths;
  if (
    Helpers.EnvironmentHelper.isLocalProductionEnvironment() &&
    localProdPagePaths &&
    localProdPagePaths.length > 0
  ) {
    paths = localProdPagePaths;
    Helpers.ConsoleHelper.log("All Pages Rendered", paths);
  } else {
    const routingService = RoutingService.getInstance();
    paths = await routingService.getAllSSGPaths(false);
  }

  Helpers.ConsoleHelper.info(
    `************* [SSG][getStaticPaths]\n`,
    `Number of Pages to Built: ${paths.length} \n`,
    "*************"
  );

  return {
    paths,
    fallback: "blocking",
  };
}

export async function getStaticProps(context) {
  // INIT REMOTES FOR NEXT
  const remoteService = Providers.RemoteService.getInstance();
  remoteService.initRemotesMap(REMOTES_MAP);

  const { params } = context;
  const slugs = (params && params.slugs) || [];
  const currentPageUrl = `/${slugs.join("/")}`;
  const routingService = RoutingService.getInstance();

  return routingService.generateStaticProps(currentPageUrl);
}

export default DynamicPage;
